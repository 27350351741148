<template>
  <div>
    <section id="knowledge-base-content">

    </section>
  </div>
</template>

<script>
import {
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
  },
  directives: {
    Ripple,
  },
  data() {
    return {

    }
  },
  computed: {
    profile() {
      return {
        name: this.$store.state.ship2u.userProfile.customer.first_name,
        email: this.$store.state.ship2u.userProfile.customer.email,
        tel: this.$store.state.ship2u.userProfile.customer.tel,
      }
    },
  },
  created() {
  },
  methods: {
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-knowledge-base.scss';
</style>
